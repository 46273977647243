<template>
  <transition name="fadePage" mode="out-in" appear>
    <div>
      <div class="relative bab-wrap">
        <div class="body_img"><img src="../../../../../public/images/BAB/b-bac.png" alt=""></div>
        <div class="body_container">
          <div class="top_nav_home">
            <transition name="fadeLogo" mode="out-in" appear>
              <router-link class="logo_index" :to="{name:'Home'}"><img src="../../../../../public/images/BAB/logo.png" alt=""></router-link>
            </transition>
            <transition name="fadeLogo" mode="out-in" appear>
              <a href="javascript:;" @click="openNavDialog(connectedStatus,1)"><img src="../../../../../public/images/more.png" alt=""></a>
            </transition>
          </div>
          <div class="title_home"><img src="../../../../../public/images/BAB/logo_big.png" alt=""></div>
          <div class="title_home_t">{{$t('menu.homeAdTitle')}}</div>
          <div class="title_home_d">{{$t('menu.homeAdTitleDes')}}</div>
          <div class="btn_con">
            <router-link v-if="connectedStatus==1" :to="{name:'BiAnBaoPower',path:'/biAnBaoPower'}">{{$t('menu.enter')}}</router-link>
            <a href="javascript:;" v-if="connectedStatus==0" @click="openDialog(connectedStatus,1)">{{$t('menu.connect')}}</a>
          </div>
          <div class="con_bac box2 mtA">
            <el-row type="flex" class="row-bg" justify="space-between">
              <el-col :span="14" class="w_wrap">
                <el-row type="flex" class="row-bg" justify="space-between">
                  <el-col :span="10" class="pic-icon"><img src="../../../../../public/images/BAB/iimg-BNB.png" alt=""></el-col>
                  <el-col :span="14" class="w_wrap mtD">
                    <div class="fs20 ffamily_B">{{formatAmountMethod(poolInfo._powRewardToday) | numFormat4}}</div>
                    <div class="fs12">{{$t('menu.BNBOutputToday')}}</div>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="10" class="w_wrap mtD">
                <div class="fs20 ffamily_B">{{formatAmountMethod(poolInfo._totalPowBnbMint)}}</div>
                <div class="fs12">BNB累计产出</div>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg mtD" justify="space-between">
              <el-col :span="14" class="w_wrap">
                <el-row type="flex" class="row-bg" justify="space-between">
                  <el-col :span="10" class="pic-icon"><img src="../../../../../public/images/BAB/img-Star.png" alt=""></el-col>
                  <el-col :span="14" class="w_wrap mtD">
                    <div class="fs20 ffamily_B">{{formatAmountMethod(poolInfo._starTodayBurn) | numFormat4}}</div>
                    <div class="fs12">{{$t('menu.starDestroyedToday')}}</div>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="10" class="w_wrap mtD">
                <div class="fs20 ffamily_B">{{formatAmountMethod(poolInfo._starTotalBurn)}}</div>
                <div class="fs12">{{$t('menu.starCumulativeDes')}}</div>
              </el-col>
            </el-row>
          </div>
          <div class="con_bac box2">
            <div class="pic_t"><img src="../../../../../public/images/BAB/icon-suanli.png" alt=""></div>
            <el-row type="flex" class="row-bg" justify="space-around">
              <el-col :span="12" class="w_wrap">
                <div class="fs20 ffamily_B">{{theWholeNetIsStrong | numFormat4}}</div>
                <div class="fs12">
                  <router-link class="icon_r" :to="{name:'BiAnBaoTotalComputing',path:'/BiAnBaoTotalComputing'}">
                    <span>{{$t('menu.qwzsl')}}</span><img src="../../../../../public/images/right.png" alt="">
                  </router-link>
                </div>
              </el-col>
              <!-- 每1000U算力产币数量=STAR今日总产出/全网总算力*1000 -->
              <!-- <el-col :span="12" class="w_wrap">
                <div class="fs20 ffamily_B">{{theNumberOfCoinsProducedPer1000U}}</div>
                <div class="fs12">{{$t('menu.currencyQuantity')}}</div>
              </el-col> -->
            </el-row>
          </div>
        </div>
      </div>
      <Bab-Right v-if="isShowRight" :conStatus="connectedStatus" @transfer="openNavDialog"></Bab-Right>
      <Bab-Wallet v-if="dialogShow" :id="0" @transfer="openDialog"></Bab-Wallet>
    </div>
  </transition>
</template>

<script>
import '../../../../style/index.css';
import '../styles/BiAnBao.css';
import BabRight from '../common/BabRight.vue';
import BabWallet from '../common/BabWallet.vue';
const bnbTreasurePool = require('../../../../utils/bnbTreasurePool')

export default {
  name: 'Home',
  components: { BabRight, BabWallet },
  data() {
    return {
      formatAmountMethod: bnbTreasurePool.default.amountConversion,
      isShowRight: false,
      dialogShow: false,
      connectedStatus: 0,  //0未连接，1已连接
      todayOutputStar: 0,
      networkDistribution_zeed: 0,
      qwzsl: 0,
      currencyQuantity: 0,//每1000U算力产币数量=STAR今日总产出/全网总算力*1000 formatAmountMethod(totalPowStarMint)/totalPow*1000
      totalNodeRewards: 0,
      rewardsPerNode: 0,
      // 全网算力
      totalPow: 0,// 全网算力
      totalSwap: 0,//全网质押总额
      totalPowStarMint: 0,//STAR今日总产出
      totalNodeStarMint: 0,//节点奖励总数量
      // 今日奖励数据
      nodeRewardToday: 0,//今日产出的star
      powRewardToday: 0,//节点产出的总数量

      totalNodes: 0,//节点总数量
      // 首页数据
      totalOutputToday: 0,//今日总产出 = _nodeRewardToday + _powRewardToday
      totalNetworkOutputSTAR: 0,// 全网已产出(star) = _totalPowStarMint + _totalNodeStarMint
      theWholeNetIsStrong: 0,// 全网总算力 = _totalPow
      theNumberOfCoinsProducedPer1000U: 0,//每1000U算力产币数量 = _powRewardToday/全网总算力*1000
      cumulativeNodeReward: 0,//累计节点奖励 = _totalNodeStarMint
      bonusPerNodeToday: 0,//今日每个节点奖励 = _nodeRewardToday / 节点总数量
      poolInfo: {
        _totalPow: 0, // 全网算力
        _totalSwap: 0, // 全网质押总额,
        _totalPowBnbMint: 0, // 算力总挖出bnb
        _swapUserCount: 0, // 购买算力用户数,
        _getRewardSwitch: 0, // 是否可以领取奖励开关
        _powRewardToday:0, // bnb今日产出
        _starTodayBurn:0, // 总销毁
        _starTotalBurn:0, //今日销毁
      }

    }
  },
  watch: {
    // // 计算：每1000U算力产币数量 = _powRewardToday/全网总算力*1000
    // powRewardToday() {
    //   console.log(this.powRewardToday, this.totalPow, this.theWholeNetIsStrong, Math.floor(this.theWholeNetIsStrong))
    //   this.theNumberOfCoinsProducedPer1000U = Math.floor(this.theWholeNetIsStrong) == 0 ? 0 : bnbTreasurePool.default.toDecimal(this.powRewardToday / this.theWholeNetIsStrong * 1000) //每1000U算力产币数量 = _powRewardToday/全网总算力*1000
    // },
    // totalPow() {
    //   this.theNumberOfCoinsProducedPer1000U = Math.floor(this.theWholeNetIsStrong) == 0 ? 0 : bnbTreasurePool.default.toDecimal(this.powRewardToday / this.theWholeNetIsStrong * 1000) //每1000U算力产币数量 = _powRewardToday/全网总算力*1000
    // },
    // 计算：今日每个节点奖励 = _nodeRewardToday / 节点总数量
    // nodeRewardToday() {
    //   this.bonusPerNodeToday = this.totalNodes == 0 ? 0 : bnbTreasurePool.default.toDecimal(this.nodeRewardToday / this.totalNodes) // 今日每个节点奖励 = _nodeRewardToday / 节点总数量
    // },
    // totalNodes() {
    //   this.bonusPerNodeToday = this.totalNodes == 0 ? 0 : bnbTreasurePool.default.toDecimal(this.nodeRewardToday / this.totalNodes) // 今日每个节点奖励 = _nodeRewardToday / 节点总数量
    // }

  },
  created: function () {
    // bnbTreasurePool.default.getWithdraw().then(res=>{console.log('挖出流水',res)})
    // bnbTreasurePool.default.getPowSummary().then(res=>{console.log('每日全网数据',res)})
    // bnbTreasurePool.default.getRefEvent().then(res=>{console.log('一级推荐人',res)})

    // bnbTreasurePool.default.getPastEvents().then(res=>{console.log('getPastEvents',res)})
    let adr = localStorage.getItem('address');
    console.log(adr, '================');
    if (adr !== '' && adr !== null && adr !== undefined) {
      this.connectedStatus = 1;
    } else {
      this.connectedStatus = 0;
    }
    let that = this
    //全网算力
    bnbTreasurePool.default.poolInfo().then(res => {
      that.poolInfo = res 
      that.totalPow = res._totalPow // 全网算力
      that.totalSwap = res._totalSwap // 全网质押总额
      that.totalPowStarMint = bnbTreasurePool.default.amountConversion(res._totalPowStarMint) // STAR今日总产出
      that.totalNodeStarMint = bnbTreasurePool.default.amountConversion(res._totalNodeStarMint) // 节点奖励总数量

      that.totalNetworkOutputSTAR = that.totalPowStarMint + that.totalNodeStarMint // 全网已产出(star) = _totalPowStarMint + _totalNodeStarMint
      that.theWholeNetIsStrong = res._totalPow // 全网总算力 = _totalPow
      that.cumulativeNodeReward = that.totalNodeStarMint // 累计节点奖励 = _totalNodeStarMint

      // 计算：每1000U算力产币数量 = _powRewardToday/全网总算力*1000
      that.theNumberOfCoinsProducedPer1000U = Math.floor(this.theWholeNetIsStrong) == 0 ? 0 : bnbTreasurePool.default.amountConversion(res._powRewardToday / this.theWholeNetIsStrong * 1000) //每1000U算力产币数量 = _powRewardToday/全网总算力*1000
      console.log('计算：每1000U算力产币数量',res._powRewardToday , this.theWholeNetIsStrong,that.theNumberOfCoinsProducedPer1000U)
      // that.theNumberOfCoinsProducedPer1000U = 100
    })

    // 今日奖励数据
    // bnbTreasurePool.default.rewarTodayInfo().then(res => {
    //   that.nodeRewardToday = bnbTreasurePool.default.amountConversion(res._nodeRewardToday)
    //   that.powRewardToday = bnbTreasurePool.default.amountConversion(res._powRewardToday)
    //   // console.log(that.nodeRewardToday,that.powRewardToday)
    //   that.totalOutputToday = that.nodeRewardToday + that.powRewardToday // 今日总产出 = _nodeRewardToday + _powRewardToday
    // })



    // //节点排行榜(获取 节点总数量)
    // bnbTreasurePool.default.nodeRanking().then(res=>{ 
    //   that.totalNodes = res._accounts.length
    //   console.log('节点总数量',that.totalNodes)
    // })
  },
  methods: {
    openNavDialog: function (connectStatus, type) {
      if (type == 1) {//type=1 打开右边导航栏弹窗，type=2 关闭右边导航栏弹窗
        this.isShowRight = true;
      }
      if (type == 2) {
        this.isShowRight = false;
        this.connectedStatus = connectStatus;

      }
    },
    openDialog(connectStatus, type) {
      if (type == 1) {//type=1 打开钱包弹窗，type=2 关闭钱包弹窗
        this.dialogShow = true;
        console.log(connectStatus, '==', type);
      }
      if (type == 2) {
        this.dialogShow = false;
        this.connectedStatus = connectStatus;
      }
    }
  }
}
</script>
<style scoped>
</style>

